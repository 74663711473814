import { Autocomplete, Box, Button, Drawer, TextField } from '@mui/material';
import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Typography } from '@mui/joy';
import { useState } from 'react';
import { useEffect } from 'react';
import {
    EverFlowNetworksDetailsOffer,
    EverFlowOffersDetailsOffer,
    addOfferData,
    getUpdateDataOffer,
    networkPortalListDetailsOffer
} from 'services/presets/offer';
import { createdByName, createdBy } from '../../../../helpers/userInfo';
const validationSchema = Yup.object().shape({
    offer_name: Yup.string()
        .trim()
        .required('Offer name  required')
        .matches(/^[^\s]+(\s[^\s]+)*$/, 'Only one space is allowed between words '),
    networkPortalList: Yup.mixed().required('Network portal list required'),
    offerUrl: Yup.string().required('OfferUrl field  required'),
    EverFlowNetworks: Yup.mixed().required('Ever flow network field  required'),
    EverFlowOffers: Yup.mixed().required('Ever flow network offer field required'),
    personalUnsub: Yup.string()
        .trim()
        .required('Personal Unsub required')
        .matches(/^[^\s]+(\s[^\s]+)*$/, 'Only one space is allowed between words '),
    networkUnsub: Yup.string()
        .trim()
        .required('Network Unsub required')
        .matches(/^[^\s]+(\s[^\s]+)*$/, 'Only one space is allowed between words ')
});
const OfferDrawer = ({ openActive, setOpenActive, defaultValues, getDataRender, setDefaultValues }) => {
    const [networkPortalList, setNetworkPortalList] = useState([]);
    const [EverFlowNetworks, setEverFlowNetworks] = useState([]);
    const [EverFlowOffers, setEverFlowOffers] = useState([]);
    const [preFilledData, setPreFilledData] = useState('');
    const [receivedData, setReceivedData] = useState({});
    const [isButtonDisabled, setButtonDisabled] = useState(false);

    const defaultValueslength = Object.keys(defaultValues).length;

    useEffect(() => {
        networkPortalListDetails();
        EverFlowNetworksDetails();
    }, []);

    const networkPortalListDetails = async () => {
        const data = await networkPortalListDetailsOffer();
        setNetworkPortalList(data.data);
    };

    const EverFlowNetworksDetails = async () => {
        const data = await EverFlowNetworksDetailsOffer();
        setEverFlowNetworks(data?.data?.data);
    };
    const EverFlowOffersDetails = async (id) => {
        const data = await EverFlowOffersDetailsOffer(id);
        setEverFlowOffers(data?.data?.data);
    };

    useEffect(() => {
        if (defaultValues && Object.keys(defaultValues).length) {
            setReceivedData(defaultValues);
            setFieldValues(defaultValues);
        } else {
            formik.resetForm();
        }
    }, [defaultValues]);

    const setFieldValues = (values) => {
        const EverFlowOffersValue = JSON?.parse(values?.EverFlowOffers);
        const EverFlowNetworksValue = JSON?.parse(values?.EverFlowNetworks);
        const networkPortalListValue = networkPortalList.find((res) =>
            res.networkPortalName === defaultValues?.networkPortalName ? defaultValues?.networkPortalName : ''
        );
        formik.setFieldValue('EverFlowOffers', EverFlowOffersValue);
        formik.setFieldValue('EverFlowNetworks', EverFlowNetworksValue);
        formik.setFieldValue('networkPortalList', networkPortalListValue);
        formik.setFieldValue('offer_name', defaultValues?.offer_name ? defaultValues?.offer_name : '');
        formik.setFieldValue('offerUrl', defaultValues?.offer_link ? defaultValues?.offer_link : '');
        formik.setFieldValue('personalUnsub', defaultValues?.personal_unsub ? defaultValues?.personal_unsub : '');
        formik.setFieldValue('networkUnsub', defaultValues?.network_unsub ? defaultValues?.network_unsub : '');
        setTimeout(() => {
            formik.validateField('EverFlowNetworks');
            formik.validateField('networkPortalList');
            formik.validateField('EverFlowOffers');
            formik.validateField('offer_name');
            formik.validateField('offerUrl');
            formik.validateField('personalUnsub');
            formik.validateField('networkUnsub');
        }, 0);
    };

    const getData = async (data) => {
        try {
            await addOfferData(data);
            getDataRender();
            setOpenActive({ drawer: false });
            setDefaultValues({});
            formik.resetForm();
            setButtonDisabled(false);
        } catch (error) {
            setButtonDisabled(false);
        }
    };

    const getUpdateData = async (data) => {
        try {
            await getUpdateDataOffer(receivedData?.offer_id, data);
            getDataRender();
            setOpenActive({ drawer: false });
            setDefaultValues({});
            formik.resetForm();
            setButtonDisabled(false);
        } catch (error) {
            setButtonDisabled(false);
        }
    };

    const formik = useFormik({
        initialValues: {
            offer_name: '',
            networkPortalList: null,
            EverFlowNetworks: null,
            EverFlowOffers: null,
            offerUrl: '',
            personalUnsub: '',
            networkUnsub: ''
        },
        validationSchema,
        onSubmit: (values) => {
            const data = {
                offer_name: values.offer_name.trim(),
                networkPortalList: values?.networkPortalList,
                network_advertiser_id: preFilledData?.network_advertiser_id,
                vertical_id: 1,
                category_id: 1,
                personal_unsub: values.personalUnsub.trim(),
                offer_link: values.offerUrl,
                network_unsub: values.networkUnsub.trim(),
                payment_type: preFilledData?.revenue_type,
                payout: preFilledData?.payout_amount,
                trackier_id: 1,
                network_id: preFilledData?.network_advertiser_id,
                network_offer_id: preFilledData?.network_offer_id,
                name: preFilledData?.name,
                created_by: createdBy,
                creator_name: createdByName,
                EverFlowNetworks: values.EverFlowNetworks,
                EverFlowOffers: values.EverFlowOffers
            };
            console.log(data, 'data');
            if (defaultValueslength === 0) {
                getData(data);
                setButtonDisabled(true);
            } else {
                getUpdateData(data);
                setButtonDisabled(true);
            }
        }
    });
    return (
        <>
            <Drawer anchor="right" open={openActive}>
                <Box
                    sx={{
                        m: 1,
                        width: '350px',
                        margin: '24px'
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <form onSubmit={formik.handleSubmit}>
                        <div>
                            <Typography
                                sx={{
                                    fontWeight: '600',
                                    fontSize: '1.3rem',
                                    mt: 4
                                }}
                            >
                                Add Offer List
                            </Typography>
                        </div>
                        <TextField
                            id="offer_name"
                            name="offer_name"
                            label="Offer Name"
                            variant="standard"
                            fullWidth
                            value={formik.values.offer_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.offer_name && formik.errors.offer_name && (
                            <div style={{ color: 'red' }}>{formik.errors.offer_name}</div>
                        )}
                        <div>
                            <Autocomplete
                                fullWidth
                                id="networkPortalList"
                                name="networkPortalList"
                                options={networkPortalList}
                                disableClearable
                                value={formik.values.networkPortalList}
                                getOptionLabel={(option) => option.networkPortalName}
                                onChange={(event, newValue) => {
                                    formik.setFieldValue('networkPortalList', newValue);
                                }}
                                onBlur={formik.handleBlur}
                                renderInput={(params) => <TextField {...params} label="Network Portal List" variant="standard" />}
                            />
                        </div>
                        {formik.touched.networkPortalList && formik.errors.networkPortalList && (
                            <div style={{ color: 'red' }}>{formik.errors.networkPortalList}</div>
                        )}
                        <div>
                            <Autocomplete
                                id="EverFlowNetworks"
                                name="EverFlowNetworks"
                                options={EverFlowNetworks}
                                fullWidth
                                disableClearable
                                value={formik.values.EverFlowNetworks}
                                getOptionLabel={(option, i) => option?.name}
                                onChange={(event, newValue) => {
                                    console.log(newValue);
                                    formik.setFieldValue('EverFlowNetworks', newValue);
                                    formik.setFieldValue('EverFlowOffers', null);
                                    formik.setFieldValue('offerUrl', '');

                                    EverFlowOffersDetails(newValue?.network_advertiser_id);
                                }}
                                onBlur={formik.handleBlur}
                                renderInput={(params) => <TextField {...params} label="Ever Flow Networks" variant="standard" />}
                            />
                        </div>
                        {formik.touched.EverFlowNetworks && formik.errors.EverFlowNetworks && (
                            <div style={{ color: 'red' }}>{formik.errors.EverFlowNetworks}</div>
                        )}
                        <div>
                            <Autocomplete
                                id="EverFlowOffers"
                                name="EverFlowOffers"
                                options={EverFlowOffers}
                                fullWidth
                                disableClearable
                                value={formik.values.EverFlowOffers}
                                getOptionLabel={(option, i) => option?.name}
                                onChange={(event, newValue) => {
                                    console.log(newValue);
                                    setPreFilledData(newValue);
                                    formik.setFieldValue('offerUrl', newValue?.destination_url);
                                    formik.setFieldValue('EverFlowOffers', newValue);
                                }}
                                onBlur={formik.handleBlur}
                                renderInput={(params) => <TextField {...params} label="Ever Flow Networks Offers" variant="standard" />}
                            />
                        </div>
                        {formik.touched.EverFlowOffers && formik.errors.EverFlowOffers && (
                            <div style={{ color: 'red' }}>{formik.errors.EverFlowOffers}</div>
                        )}
                        <div>
                            <TextField
                                InputProps={{ readOnly: true }}
                                fullWidth
                                id="offerUrl"
                                name="offerUrl"
                                label="Offer Url"
                                variant="standard"
                                value={formik.values.offerUrl}
                            />
                        </div>
                        <div>
                            <TextField
                                id="personalUnsub"
                                name="personalUnsub"
                                label="Personal Unsub"
                                variant="standard"
                                fullWidth
                                value={formik.values.personalUnsub}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </div>
                        {formik.touched.personalUnsub && formik.errors.personalUnsub && (
                            <div style={{ color: 'red' }}>{formik.errors.personalUnsub}</div>
                        )}
                        <div>
                            <TextField
                                id="networkUnsub"
                                name="networkUnsub"
                                label="Network Unsub"
                                variant="standard"
                                fullWidth
                                value={formik.values.networkUnsub}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </div>
                        {formik.touched.networkUnsub && formik.errors.networkUnsub && (
                            <div style={{ color: 'red' }}>{formik.errors.networkUnsub}</div>
                        )}
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
                            <Button
                                onClick={() => {
                                    setOpenActive({ drawer: false });
                                    setFieldValues(defaultValues);
                                    formik.resetForm();
                                    setDefaultValues({});
                                }}
                                variant="outlined"
                            >
                                Cancel
                            </Button>
                            <Button disabled={isButtonDisabled} variant="contained" type="submit">
                                Submit
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Drawer>
        </>
    );
};

export default OfferDrawer;
